// // PropTypes & React HOOKS
// import PropTypes from 'prop-types';
// import React from 'react';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';

// export default function StudentsTable({ data, columns, handleClick }) {
//   const navigate = useNavigate();

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Inicializar la tabla:
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//   return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : ('')}
//                     </div>
//                     <div className="mx-4">
//                       {column.canFilter && column.render('Filter')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);
//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${index % 2 === 0 && 'bg-indigo-50'}`}
//                 >
//                   <td className={`text-sm font-bold pl-4 py-4 w-1/16`}>
//                     {row.original.CODI_BECARI}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.NOM}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.COGNOM}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.DNI}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.DATA_NAIXAMENT}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.NUM_SS}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-2/16`}>
//                     {row.original.Escola_Nom}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-3/16`}>
//                     {row.original.Professor_NombreCompleto}
//                   </td>
//                   <td className={`text-sm font-medium pl-4 py-4 w-1/16 text-center`}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 1} className="p-0 m-0">
//                 <SchoolsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
// };














// import PropTypes from 'prop-types';
// import React from 'react';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';
// import dayjs from 'dayjs';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';

// export default function StudentsTable({ data, columns, handleClick }) {
//   const navigate = useNavigate();

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Inicializar la tabla:
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//   return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : ('')}
//                     </div>
//                     <div className="mx-4">
//                       {column.canFilter && column.render('Filter')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);
//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${index % 2 === 0 && 'bg-indigo-50'}`}
//                 >
//                   {row.cells.map((cell) => {
//                     const cellValue = cell.value;
//                     let formattedValue = cellValue;

//                     if (cell.column.id === 'DATA_INICI' || cell.column.id === 'DATA_FINALITZACIO') {
//                       formattedValue = dayjs(cellValue).format('DD/MM/YYYY');
//                     } else if (cell.column.id === 'CHECK_TASCA_INICI' || cell.column.id === 'CHECK_TASCA_FINAL') {
//                       formattedValue = cellValue ? '✔️' : '❌';
//                     }

//                     return (
//                       <td
//                         key={cell.column.id}
//                         className="text-sm font-medium pl-4 py-4"
//                         style={{ width: cell.column.width || '12.5%' }}
//                       >
//                         {formattedValue}
//                       </td>
//                     );
//                   })}
//                   <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 1} className="p-0 m-0">
//                 <SchoolsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
// };





// import PropTypes from 'prop-types';
// import React from 'react';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';
// import dayjs from 'dayjs';

// export default function StudentsTable({ data, columns, handleClick }) {
//   const navigate = useNavigate();

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Inicializar la tabla:
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//   return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : ('')}
//                     </div>
//                     <div className="mx-4">
//                       {column.canFilter && column.render('Filter')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);
//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${index % 2 === 0 && 'bg-indigo-50'}`}
//                 >
//                   {columns.map((column) => (
//                     <td
//                       key={column.id}
//                       className="text-sm font-medium pl-4 py-4"
//                       style={{ width: column.width || '12.5%' }}
//                     >
//                       {column.Cell ? column.Cell(row) : row.values[column.accessor]}
//                     </td>
//                   ))}
//                   <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 1} className="p-0 m-0">
//                 <SchoolsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
// };



// import PropTypes from 'prop-types';
// import React from 'react';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import StudentsTableBar from './StudentsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';

// export default function StudentsTable({ data, columns, handleClick, refreshData }) {
//   const navigate = useNavigate();

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Inicializar la tabla:
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//   return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                       {column.isSorted ? (
//                         column.isSortedDesc ? (
//                           <FilterDown
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         ) : (
//                           <FilterUp
//                             width={10}
//                             height={10}
//                             className="inline-block ml-1 -mt-1"
//                             fill="black"
//                           />
//                         )
//                       ) : ('')}
//                     </div>
//                     <div className="mx-4">
//                       {column.canFilter && column.render('Filter')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);
//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${index % 2 === 0 && 'bg-indigo-50'}`}
//                 >
//                   {row.cells.map((cell) => (
//                     <td
//                       key={cell.column.id}
//                       className="text-sm font-medium pl-4 py-4"
//                       style={{ width: cell.column.width || '12.5%' }}
//                     >
//                       {cell.render('Cell')}
//                     </td>
//                   ))}
//                   <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 1} className="p-0 m-0">
//                 <StudentsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                   onSuccess={refreshData}
//                   onSuccessPracts={refreshData}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
//   refreshData: PropTypes.func.isRequired, // Ensure this is here
// };

// -------------------------------------------------------------------------------------------------------


// import PropTypes from 'prop-types';
// import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
// import { useNavigate } from 'react-router-dom';
// import { useState } from 'react';

// // COMPONENTS
// import FilterUp from 'components/icons/FilterUp';
// import FilterDown from 'components/icons/FilterDown';
// import SchoolsTableBar from './SchoolsTableBar';
// import StudentsTableBar from './StudentsTableBar';
// import { FaCalendarAlt } from 'react-icons/fa';

// export default function StudentsTable({ data, columns, handleClick, refreshData }) {
//   const navigate = useNavigate();
//   const [selectedRows, setSelectedRows] = useState({});  // Almacena los seleccionados como un objeto
//   const [tascaStatus, setTascaStatus] = useState('');
//   const [showForm, setShowForm] = useState(false);

//   const handleCalendarClick = (student) => {
//     navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
//   };

//   // Función para obtener información de un usuario de la API utilizando ID_ALUMNES
//   const fetchStudentData = async (studentId) => {
//     try {
//       const response = await fetch(`https://api-codina-af1a88eea621.herokuapp.com/students/${studentId}`);
//       const studentData = await response.json();
//       console.log('Información del alumno:', studentData);
//     } catch (error) {
//       console.error('Error al obtener información del alumno:', error);
//     }
//   };

//   // Maneja el clic del checkbox para seleccionar usuarios
//   const handleCheckboxClick = (row) => {
//     const studentId = row.original.ID_ALUMNES;

//     setSelectedRows((prev) => {
//       // Si el usuario ya está seleccionado, lo deseleccionamos
//       const newState = {
//         ...prev,
//         [studentId]: !prev[studentId], // Alterna el estado seleccionado de la fila
//       };

//       // Si el usuario acaba de ser seleccionado, hacemos la llamada a la API
//       if (!prev[studentId]) {
//         fetchStudentData(studentId);
//       }

//       return newState; // Devuelve el nuevo estado
//     });
//   };

//   // Función para manejar el cambio de "check tasca inici"
//   const handleTascaChange = (e) => {
//     setTascaStatus(e.target.value); // Actualiza el estado de "check tasca inici"
//   };

//   // Función para aplicar el cambio a todos los usuarios seleccionados
//   const handleApplyChanges = () => {
//     const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]); // Solo selecciona los que están en `true`
//     console.log('Usuarios seleccionados:', selectedUsers);
//     console.log('Nuevo estado de tasca inici:', tascaStatus);

//     // Aquí harías la llamada a la API para actualizar el estado de tasca inici
//     selectedUsers.forEach((userId) => {
//       console.log(`Actualizando usuario ${userId} con tasca inici: ${tascaStatus}`);
//     });

//     // Ocultar el formulario después de aplicar los cambios
//     setShowForm(false);
//   };

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     nextPage,
//     previousPage,
//     canNextPage,
//     canPreviousPage,
//     pageOptions,
//     setPageSize,
//     state,
//     prepareRow
//   } = useTable(
//     { columns, data, initialState: { pageSize: 25 } },
//     useGlobalFilter,
//     useFilters,
//     useSortBy,
//     usePagination
//   );

//   const { pageIndex, pageSize } = state;

//  return (
//     <div className="flex flex-col">
//       <div className="align-middle inline-block min-w-full">
//         <table
//           className="table-auto min-w-full border-2 border-indigo-800"
//           {...getTableProps()}
//         >
//           <thead className="bg-indigo-900">
//             {headerGroups.map((headerGroup) => (
//               <tr
//                 className="divide-x divide-dashed divide-indigo-600"
//                 key={headerGroup.id}
//                 {...headerGroup.getHeaderGroupProps()}
//               >
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Seleccionar</div>
//                 </th>
//                 {headerGroup.headers.map((column) => (
//                   <th
//                     className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
//                     key={column.id}
//                     {...column.getHeaderProps(
//                       column.getSortByToggleProps({ title: undefined })
//                     )}
//                   >
//                     <div className="text-left text-sm ml-4">
//                       {column.render('Header')}
//                     </div>
//                   </th>
//                 ))}
//                 <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
//                   <div className="text-left text-sm ml-4">Calendari</div>
//                 </th>
//               </tr>
//             ))}
//           </thead>
//           <tbody
//             style={{ borderColor: '#9CA3AF' }}
//             className="bg-white divide-y divide-gray-200"
//             {...getTableBodyProps()}
//           >
//             {page.map((row, index) => {
//               prepareRow(row);

//               const studentId = row.original.ID_ALUMNES;
//               const isSelected = !!selectedRows[studentId];  // Verifica si el alumno está seleccionado

//               return (
//                 <tr
//                   key={row.id}
//                   {...row.getRowProps()}
//                   onClick={(e) => handleClick && handleClick(e, row.original)}
//                   className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
//                     ${isSelected ? 'bg-green-200' : index % 2 === 0 ? 'bg-indigo-50' : ''}`}
//                 >
//                   <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <input
//                       type="checkbox"
//                       checked={isSelected}  // Refleja el estado de si está seleccionado
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCheckboxClick(row);  // Pasa toda la fila
//                       }}
//                       className="form-checkbox h-5 w-5 text-indigo-600"
//                     />
//                   </td>
//                   {row.cells.map((cell) => (
//                     <td
//                       key={cell.column.id}
//                       className="text-sm font-medium pl-4 py-4"
//                       style={{ width: cell.column.width || '12.5%' }}
//                     >
//                       {cell.render('Cell')}
//                     </td>
//                   ))}
//                   <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
//                     <button 
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         handleCalendarClick(row.original);
//                       }}
//                       className="text-indigo-800 hover:text-blue-700"
//                     >
//                       <FaCalendarAlt size={24} />
//                     </button>
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td colSpan={columns.length + 2} className="p-0 m-0">
//                 <StudentsTableBar
//                   previousPage={previousPage}
//                   canPreviousPage={canPreviousPage}
//                   pageIndex={pageIndex}
//                   pageOptions={pageOptions}
//                   nextPage={nextPage}
//                   canNextPage={canNextPage}
//                   pageSize={pageSize}
//                   setPageSize={setPageSize}
//                   datos={data}
//                   onSuccess={refreshData}
//                   onSuccessPracts={refreshData}
//                 />
//               </td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>

//       {/* Mostrar el botón para abrir el formulario si hay usuarios seleccionados */}
//       {Object.keys(selectedRows).some((key) => selectedRows[key]) && (
//         <button
//           onClick={() => setShowForm(true)}
//           className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
//         >
//           Cambiar Tasca Inici
//         </button>
//       )}

//       {/* Formulario para cambiar "check tasca inici" */}
//       {showForm && (
//         <div className="mt-4">
//           <label htmlFor="tascaStatus" className="block text-sm font-medium text-gray-700">
//             Selecciona el nuevo estado de Tasca Inici:
//           </label>
//           <select
//             id="tascaStatus"
//             value={tascaStatus}
//             onChange={handleTascaChange}
//             className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
//           >
//             <option value="">Seleccione una opción</option>
//             <option value="SI">SI</option>
//             <option value="NO">NO</option>
//             <option value="PENDENT">PENDENT</option>
//           </select>
//           <button
//             onClick={handleApplyChanges}
//             className="mt-4 bg-green-500 text-white py-2 px-4 rounded"
//           >
//             Aplicar cambios
//           </button>
//         </div>
//       )}
//     </div>
//   );
// }

// StudentsTable.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   handleClick: PropTypes.func,
//   refreshData: PropTypes.func.isRequired,
// };



import PropTypes from 'prop-types';
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { useState, useContext } from 'react';
import { UserContext } from 'contexts/UserContext';

// COMPONENTS
import FilterUp from 'components/icons/FilterUp';
import FilterDown from 'components/icons/FilterDown';
import SchoolsTableBar from './SchoolsTableBar';
import StudentsTableBar from './StudentsTableBar';
import { FaCalendarAlt } from 'react-icons/fa';

export default function StudentsTable({ data, columns, handleClick, refreshData }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [selectedRows, setSelectedRows] = useState({});  // Almacena los seleccionados
  const [iniciCheck, setIniciCheck] = useState('');  // Almacena el nuevo estado de INICI_CHECK
  const [fiCheck, setFiCheck] = useState('');  // Almacena el nuevo estado de FI_CHECK
  const [showForm, setShowForm] = useState(false);  // Controla la visibilidad del formulario

  const handleCalendarClick = (student) => {
    navigate(`/calendar/${student.ID_ALUMNES}/${student.ID_ESCOLA}/${student.ID_PRACTIQUES}`);
  };

  // Función para obtener información de un usuario de la API utilizando ID_ALUMNES
  const fetchStudentData = async (studentId) => {
    try {
      const response = await fetch(`https://api-codina-af1a88eea621.herokuapp.com/students/${studentId}`);
      const studentData = await response.json();
      console.log('Información del alumno:', studentData);
    } catch (error) {
      console.error('Error al obtener información del alumno:', error);
    }
  };

  // Maneja el clic del checkbox para seleccionar usuarios
  const handleCheckboxClick = (row) => {
    const studentId = row.original.ID_ALUMNES;

    setSelectedRows((prev) => {
      const newState = {
        ...prev,
        [studentId]: !prev[studentId], // Alterna el estado seleccionado de la fila
      };

      if (!prev[studentId]) {
        // Llamada a la API cuando se selecciona el checkbox
        fetchStudentData(studentId);
      }

      return newState;
    });
  };

  // Función para manejar los cambios en INICI_CHECK y FI_CHECK
  const handleIniciCheckChange = (e) => {
    setIniciCheck(e.target.value);  // Actualiza el estado de INICI_CHECK
  };

  const handleFiCheckChange = (e) => {
    setFiCheck(e.target.value);  // Actualiza el estado de FI_CHECK
  };

  // // Función para aplicar el cambio a todos los usuarios seleccionados
  // const handleApplyChanges = () => {
  //   const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]);
    
  //   selectedUsers.forEach(async (userId) => {
  //     // Llamada a la API para actualizar los campos INICI_CHECK y FI_CHECK
  //     try {
  //       const response = await fetch(`http://localhost:5000/students/${userId}`, {
  //         method: 'PATCH',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           INICI_CHECK: iniciCheck,  // Actualizamos INICI_CHECK
  //           FI_CHECK: fiCheck,  // Actualizamos FI_CHECK
  //         }),
  //       });

  //       if (response.ok) {
  //         console.log(`Usuario ${userId} actualizado con INICI_CHECK: ${iniciCheck}, FI_CHECK: ${fiCheck}`);
  //       } else {
  //         console.error(`Error al actualizar el usuario ${userId}`);
  //       }
  //     } catch (error) {
  //       console.error('Error en la solicitud PATCH:', error);
  //     }
  //   });

  //   // Ocultar el formulario después de aplicar los cambios
  //   setShowForm(false);
  // };

  // Función para aplicar el cambio a todos los usuarios seleccionados
const handleApplyChanges = async () => {
  const selectedUsers = Object.keys(selectedRows).filter((key) => selectedRows[key]);

  // Verificar si hay usuarios seleccionados
  if (selectedUsers.length === 0) {
    console.log('No hay usuarios seleccionados');
    return;
  }

  console.log('Usuarios seleccionados para aplicar cambios:', selectedUsers);

  // Aplicar los cambios y actualizar la tabla localmente
  for (const userId of selectedUsers) {
    try {
      const response = await fetch(`https://api-codina-af1a88eea621.herokuapp.com/students/${userId}`, {
      // const response = await fetch(`http://localhost:5000/students/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          INICI_CHECK: iniciCheck,
          FI_CHECK: fiCheck,
        }),
      });

      if (response.ok) {
        console.log(`Usuario ${userId} actualizado con INICI_CHECK: ${iniciCheck}, FI_CHECK: ${fiCheck}`);
      } else {
        console.error(`Error al actualizar el usuario ${userId}`);
      }
    } catch (error) {
      console.error('Error en la solicitud PATCH:', error);
    }
  }

  // Llamar a refreshData para recargar los datos
  await refreshData();

  setSelectedRows({});

  // Ocultar el formulario después de aplicar los cambios
  setShowForm(false);
};


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = state;

 return (
    <div className="flex flex-col">
      <div className="align-middle inline-block min-w-full">
        <table
          className="table-auto min-w-full border-2 border-indigo-800"
          {...getTableProps()}
        >
          {/* <thead className="bg-indigo-900">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-indigo-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                  <div className="text-left text-sm ml-4">Seleccionar</div>
                </th>
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                  >
                    <div className="text-left text-sm ml-4">
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
                <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                  <div className="text-left text-sm ml-4">Calendari</div>
                </th>
              </tr>
            ))}
          </thead> */
          <thead className="bg-indigo-900">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed divide-indigo-600"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {/* Columna de selección (sin filtros ni sorting) */}
                {/* <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                  <div className="text-left text-sm ml-4">Seleccionar</div>
                </th> */}
                {user.role_id === 2 && (
                  <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                    <div className="text-left text-sm ml-4">Seleccionar</div>
                  </th>
                )}

                {/* Columnas con ordenación y filtros */}
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-indigo-800 font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                  >
                    <div className="text-left text-sm ml-4 flex items-center">
                      {/* Título de la columna */}
                      {column.render('Header')}

                      {/* Mostrar ícono de ordenación */}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        ) : (
                          <FilterUp
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        )
                      ) : null}
                    </div>

                    {/* Mostrar el filtro si está habilitado */}
                    <div className="mx-4">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}

                {/* Columna de calendario (sin filtros ni sorting) */}
                <th className="text-white py-2 hover:bg-indigo-800 font-ms-semi flex-center">
                  <div className="text-left text-sm ml-4">Calendari</div>
                </th>
              </tr>
            ))}
          </thead>
          }
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row);

              const studentId = row.original.ID_ALUMNES;
              const isSelected = !!selectedRows[studentId];  // Verifica si el alumno está seleccionado

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-indigo-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-indigo-200
                    ${isSelected ? 'bg-indigo-200' : index % 2 === 0 ? 'bg-indigo-50' : ''}`}
                >
                  {/* <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
                    <input
                      type="checkbox"
                      checked={isSelected}  // Refleja el estado de si está seleccionado
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCheckboxClick(row);  // Pasa toda la fila
                      }}
                      className="form-checkbox h-5 w-5 text-indigo-800"
                    />
                  </td> */}
                  {/* Celda de selección solo para admin (role_id: 2) */}
                  {user.role_id === 2 && (
                    <td
                      className="text-sm font-medium text-center cursor-pointer"
                      style={{ width: '6.25%' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCheckboxClick(row); // Selecciona/deselecciona el checkbox al hacer clic en el td
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleCheckboxClick(row)}
                        className="form-checkbox h-5 w-5 text-indigo-800"
                        onClick={(e) => e.stopPropagation()}
                      />
                    </td>
                  )}

                  {row.cells.map((cell) => (
                    <td
                      key={cell.column.id}
                      className="text-sm font-medium pl-4 py-4"
                      style={{ width: cell.column.width || '12.5%' }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                  <td className="text-sm font-medium text-center" style={{ width: '6.25%' }}>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCalendarClick(row.original);
                      }}
                      className="text-indigo-800 hover:text-blue-700"
                    >
                      <FaCalendarAlt size={24} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columns.length + 2} className="p-0 m-0">
                <StudentsTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={data}
                  onSuccess={refreshData}
                  onSuccessPracts={refreshData}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      {/* Mostrar el botón para abrir el formulario si hay usuarios seleccionados */}
      {Object.keys(selectedRows).some((key) => selectedRows[key]) && (
        <button
          onClick={() => setShowForm(true)}
          className="mt-4 bg-indigo-900 text-white text-sm py-2 px-4 rounded"
        >
          Cambiar Check Tasca Inici y Check Tasca Final
        </button>
      )}

      {/* Formulario para cambiar "INICI_CHECK" y "FI_CHECK" */}
        {showForm && (
    <div className="mt-6 bg-indigo-100 p-6 rounded-lg shadow-lg max-w-lg mx-auto">
      <h3 className="text-indigo-900 font-bold text-lg mb-4">Actualizar Estado</h3>
      
      {/* INICI_CHECK */}
      <label htmlFor="iniciCheck" className="block text-sm font-semibold text-indigo-800 mb-2">
        Selecciona el nuevo estado de Check Tasca Inici:
      </label>
      <select
        id="iniciCheck"
        value={iniciCheck}
        onChange={handleIniciCheckChange}
        className="block w-full px-4 py-2 border border-indigo-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-200"
      >
        <option value="">Seleccione una opción</option>
        <option value="0">No</option>
        <option value="1">Sí</option>
        <option value="2">Pendent</option>
      </select>

      {/* FI_CHECK */}
      <label htmlFor="fiCheck" className="block text-sm font-semibold text-indigo-800 mt-6 mb-2">
        Selecciona el nuevo estado de Check Tasca Final:
      </label>
      <select
        id="fiCheck"
        value={fiCheck}
        onChange={handleFiCheckChange}
        className="block w-full px-4 py-2 border border-indigo-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition ease-in-out duration-200"
      >
        <option value="">Seleccione una opción</option>
        <option value="0">No</option>
        <option value="1">Sí</option>
        <option value="2">Pendent</option>
      </select>

      {/* Botón Aplicar Cambios */}
      <button
        onClick={handleApplyChanges}
        className="w-full mt-6 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-3 rounded-md shadow-lg transition ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Aplicar cambios
      </button>
    </div>
  )}

    </div>
  );
}

StudentsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  refreshData: PropTypes.func.isRequired,
};


