import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { UserContext } from 'contexts/UserContext';
import logoExcel from 'assets/img/logoExcel.png';
import logoExcelRound from 'assets/img/Excel-2-icon.png';
import logoTxt from 'assets/img/logoTxt.png';
import flecha from 'assets/img/imageInvert.png';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

export default function StudentsTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  datos,
  onSuccess,
  onSuccessPracts,
}) {
  const [file, setFile] = useState(null);
  const { user } = useContext(UserContext);
  const [schoolData, setSchoolData] = useState({});
    const [studentData, setStudentData] = useState([]);
  const [studentDataMap, setStudentDataMap] = useState({});
  const [selectedCodiCentre, setSelectedCodiCentre] = useState('');
  const rowsEmpty = datos.length === 0;

  useEffect(() => {
    // Fetch school data
    const fetchSchoolData = async () => {
      try {
        const response = await fetch('https://api-codina-af1a88eea621.herokuapp.com/schools/');
        const data = await response.json();
        const schoolDataMap = data.reduce((acc, school) => {
          acc[school.CODI] = school.CENTRE;
          return acc;
        }, {});
        setSchoolData(schoolDataMap);
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    };


    // const fetchStudentData = async () => {
    //   try {
    //     const response = await fetch('http://localhost:5000/students/all');
    //     const data = await response.json();
    //     const studentDataMap = data.reduce((acc, student) => {
    //       // acc[student.CODI_BECARI] = student.NUM_SS;
    //       acc[student.CODI_BECARI] = student.DATA_NAIXAMENT;
    //       return acc;
    //     }, {});
    //     setStudentDataMap(studentDataMap);
    //   } catch (error) {
    //     console.error('Error fetching student data:', error);
    //   }
    // };

    const fetchStudentData = async () => {
      try {
        const response = await fetch('https://api-codina-af1a88eea621.herokuapp.com/students/all');
        const data = await response.json();

        console.log('Raw data fetched:', data);
        setStudentData(data);
        const studentDataMap = data.reduce((acc, student) => {
          acc[student.DNI_NIE_PASSAPORT] = {
            numSS: student.NUM_SS,
            fechaNacimiento: student.DATA_NAIXAMENT,
            fechaInicio: student.DATA_INICI,
            fechaFin: student.DATA_FINALITZACIO,
            checkInici: student.CHECK_TASCA_INICI,
            checkFinal: student.CHECK_TASCA_FINAL
          };
          return acc;
        }, {});
        console.log('Student data map created:', studentDataMap);
        setStudentDataMap(studentDataMap);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchSchoolData();
    fetchStudentData();
  }, []);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(datos);
    const workbook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'students.xlsx');
  };

  const exportToText = () => {
    let textData = '';
    const filteredData = selectedCodiCentre ? datos.filter(student => student.CODI_CENTRE === selectedCodiCentre && student.CHECK_TASCA_INICI === 2 && student.CHECK_TASCA_FINAL === 2) : datos;
    filteredData.forEach(student => {
      const tipoRegistro = '01'; // Tipo de registro: '01'
      const codigoEmpresa = (student.CODI_CENTRE || '').padStart(5, '0'); // Código de empresa
      const codigoTrabajo = (schoolData[student.CODI_CENTRE] || '').padStart(5, '0'); // Código de trabajo (centro)
      const codigoTrabajador = (student.CODI_BECARI || '').padStart(6, '0'); // Código de trabajador
      const studentInfo = studentDataMap[student.DNI_NIE_PASSAPORT] || {};
      const dni = (student.DNI_NIE_PASSAPORT || '').padEnd(9, ' '); // DNI
      // const apellidosNombre = (student.COGNOMS_NOM || '').padEnd(30, ' '); // Apellidos y nombre
      const apellidosNombre = (student.COGNOMS_NOM || '')
    .replace(',', '') // Elimina la coma
    .split(' ') // Divide la cadena en palabras
    .filter(word => word) // Filtra elementos vacíos (por si hay más de un espacio)
    .join(' ') // Vuelve a unir las palabras con un espacio entre ellas
    .toUpperCase() // Convierte todo a mayúsculas
    .padEnd(30, ' '); // Rellena con espacios hasta 30 caracteres
      const numMatricula = '00000'; // Numero matricula
      // const numSS = (studentDataMap[student.CODI_BECARI] || '').padStart(12, '0'); // Número de Seguridad Social, ajustado a 12 caracteres con ceros a la izquierda
      const numSS = (studentInfo.numSS || '').padStart(12, '0');
      const padre = '.'.padEnd(15, ' ');
      const madre = '.'.padEnd(15, ' ');
      const hscl = 'HSCL'.padEnd(33, ' ');
      const numEscaleraPisoPuerta = '0000';
      const municipio = 'BARCELONA'.padEnd(20, ' ');
      const cp = '08000'.padEnd(15, '0');
      const clavePercepcion = 'A'.padEnd(8, '0');
      const tipoRetencion = 'A'.padEnd(21, '0');
      const codigoSerie = ''.padEnd(16, ' ');
      const becari = 'BECARI'.padStart(9, '0');
      const becari0 = 'BECARI'.padStart(10, ' ');
      const grupoTarifa = '0711301MNN'.padStart(12, ' ');
      const fechaNacimiento =  format(new Date(studentInfo.fechaNacimiento), 'yyyyddMM').padStart(9, ' ');
      const fechaInicio =  format(new Date(studentInfo.fechaInicio), 'yyyyMMdd');
      const indicadorPluriempleado = 'N'.padEnd(31, '0');
      const indicadorTiempoParcial = 'D000000000000000N000000000000000000000000000000000000000000000000000000000000000000'.padStart(123, ' ');
      const horasAndTipoContrato = '000100'.padStart(36, ' ');
      const fechaFin =  format(new Date(studentInfo.fechaFin), 'yyyyMMdd');
      const nivelEstudiosTerminados = '53'.padEnd(11, ' ');
      const numContrato = '00000000000190001000000000000019000100 0000000000000000190001001900010000000000000000';
      const datosPagasExtras = '000000000'.padStart(14, ' ');
      const finalDescuadrado = '000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'.padStart(197, ' ');
      const finalDescuadrado1 = '00000000000000000072400000000000000000000000000000000000000000000000000000000    00   00  000000000000    0100'.padStart(410, ' ');
      const finalPredeterminado = '00000000000000                                          0801972400000000000003724                                                                                                                                                                                                                                                                   '.padStart(453, ' ');


      textData += `${tipoRegistro}${codigoEmpresa}${codigoTrabajo}${codigoTrabajador}${dni}${apellidosNombre}${numMatricula}${numSS}${padre}${madre}${hscl}${numEscaleraPisoPuerta}${municipio}${municipio}${cp}${clavePercepcion}${tipoRetencion}${codigoSerie}${becari}${becari0}${grupoTarifa}${fechaNacimiento}${fechaInicio}${numEscaleraPisoPuerta}${numEscaleraPisoPuerta}${fechaInicio}${fechaInicio}${indicadorPluriempleado}${indicadorTiempoParcial}${horasAndTipoContrato}${fechaInicio}${fechaFin}${nivelEstudiosTerminados}${numContrato}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${datosPagasExtras}${finalDescuadrado}${finalDescuadrado1}${finalPredeterminado}\n`;
    });

    const textBlob = new Blob([textData], { type: 'text/plain' });
    saveAs(textBlob, 'students.txt');
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    readExcelFile(selectedFile);
  };

  // const readExcelFile = (file) => {
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     const arrayBuffer = event.target.result;
  //     const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  //     const sheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[sheetName];
  //     // const jsonData = XLSX.utils.sheet_to_json(worksheet);
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, cellDates: true }); 
  //     console.log(jsonData);
  //     uploadStudents(jsonData);
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  const readExcelFile = (file) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const arrayBuffer = event.target.result;
    const workbook = XLSX.read(arrayBuffer, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];

    // Convertir la hoja a JSON
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, cellDates: true }); 

    // Concatenar la SS
    const formattedStudents = jsonData.map(student => {
      // Concatenamos las tres columnas en el formato necesario
      student.SS = `${student['00']} ${student['00000000']} ${student['11']}`.trim(); 
      
      // Opcional: Eliminar las columnas si no se necesitan más
      delete student['00'];
      delete student['00000000'];
      delete student['11'];
      
      return student;
    });

    // Hacer POST con los estudiantes y el SS concatenado
    uploadStudents(formattedStudents);
  };
  reader.readAsArrayBuffer(file);
};

    const handleFileUploadPracts = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    readExcelFilePracts(selectedFile);
  };

  const readExcelFilePracts = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      console.log(jsonData);
      uploadPractices(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  // const uploadStudents = async (students) => {
  //   try {
  //     const url = user.role_id === 1 ? `http://localhost:5000/import/massImport/${user.id}` : 'http://localhost:5000/import/massImport';
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ students }),
  //     });
  //     if (response.ok) {
  //       // alert('Students imported successfully!');
  //       toast.success('Alumnes importats amb exit!');
  //       onSuccess(); // Call the success function to update the table
  //     } else {
  //       toast.error('Error al importar els alumnes');
  //     }
  //   } catch (error) {
  //     toast.error('Error al importar');
  //   }
  // };

  const uploadStudents = async (students) => {
  try {
      const formattedStudents = students.map(student => {
    return {
      NOM: student.NOM,  // Notación de punto
      COGNOM: student.COGNOMS,  // Notación de punto
      DNI: student["DNI NIE PASS"],  // Aquí usamos notación de corchetes porque hay espacios
      DATA_NAIXAMENT: student['DATA NAIXAMENT'],  // Notación de corchetes porque hay espacios
      NUM_SS: student.SS,  // Usamos el valor concatenado de la SS
      INICI_PRACTIQUES: student['INICI PRACTIQUES'],  // Notación de corchetes por espacios
      FI_PRACTIQUES: student['FI PRACTIQUES']  // Notación de corchetes por espacios
    };
  });

    const url = user.role_id === 1 ? `https://api-codina-af1a88eea621.herokuapp.com/import/massImport/${user.id}` : 'https://api-codina-af1a88eea621.herokuapp.com/import/massImport';
    // const url = user.role_id === 1 ? `http://localhost:5000/import/massImport/${user.id}` : 'http://localhost:5000/import/massImport';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({ students }),
      body: JSON.stringify({ students: formattedStudents }),
    });

    const result = await response.json();

    if (response.ok) {
      const { successCount, errorDetails } = result;
      onSuccess(); // Llama a la función de éxito para actualizar la tabla
      toast.success(`¡${successCount} alumnes importats amb exit!`);
      
      if (errorDetails.length > 0) {
        errorDetails.forEach(detail => {
          toast.error(`Error al importar el alumno ${detail.student.NOM} ${detail.student.COGNOM}: ${detail.error}`);
        });
      }

      
    } else {
      toast.error('Error al importar los alumnos');
    }
  } catch (error) {
    console.error('Error importing students:', error);
    // toast.error('Error al importar');
  }
};

const uploadPractices = async (practices) => {
  try {
    // Formateamos las prácticas para que coincidan con los nombres de columnas del Excel
    const formattedPractices = practices.map(practice => {
      return {
        DNI: practice["DNI NIE PASS"],  // Notación de corchetes porque hay espacios
        GENER: practice.GENER,  // Gener -> January
        FEBRER: practice.FEBRER,  // February
        MARÇ: practice.MARÇ,  // March
        ABRIL: practice.ABRIL,  // April
        MAIG: practice.MAIG,  // May
        JUNY: practice.JUNY,  // June
        JULIOL: practice.JULIOL,  // July
        AGOST: practice.AGOST,  // August
        SETEMBRE: practice.SETEMBRE,  // September
        OCTUBRE: practice.OCTUBRE,  // October
        NOVEMBRE: practice.NOVEMBRE,  // November
        DESEMBRE: practice.DESEMBRE // December
      };
    });

    const url = user.role_id === 1 ? `https://api-codina-af1a88eea621.herokuapp.com/import/practices/${user.id}` : 'https://api-codina-af1a88eea621.herokuapp.com/import/practices';
    // const url = user.role_id === 1 ? `http://localhost:5000/import/practices/${user.id}` : 'http://localhost:5000/import/practices';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ practices: formattedPractices }),
    });

    const result = await response.json();

    if (response.ok) {
      const { successCount, errorDetails } = result;
      onSuccessPracts(); // Llama a la función de éxito para actualizar la tabla
      toast.success(`${successCount} pràctiques importades correctament!`);
      
      if (errorDetails.length > 0) {
        errorDetails.forEach(detail => {
          toast.error(`Error al importar la pràctica de ${detail.practice.DNI}: ${detail.error}`);
        });
      }
    } else {
     toast.error('Error al importar les pràctiques');
    }
  } catch (error) {
    console.error('Error importing practices:', error);
    // toast.error('Error al importar les pràctiques');
  }
};




// const uploadPractices = async (practices) => {
//     try {
//       const url = user.role_id === 1 ? `https://api-codina-af1a88eea621.herokuapp.com/import/practices/${user.id}` : 'https://api-codina-af1a88eea621.herokuapp.com/import/practices';
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ practices }),
//       });

//       const result = await response.json();

//       if (response.ok) {
//         const { successCount, errorDetails } = result;
//         onSuccessPracts(); // Llama a la función de éxito para actualizar la tabla
//         toast.success(`${successCount} dades d'alumnes importades al calendari!`);
        
//         if (errorDetails.length > 0) {
//           errorDetails.forEach(detail => {
//             toast.error(`Error al importar la práctica de ${detail.practice.DNI}: ${detail.error}`);
//           });
//         }
//       } else {
//         toast.error('Error al importar las prácticas');
//       }
//     } catch (error) {
//       console.error('Error importing practices:', error);
//       // toast.error('Error al importar');
//     }
//   };

  // const downloadTemplate = (type) => {
  //   let templateData = [];
  //   let sheetName = '';

  //   if (type === 'students') {
  //     templateData = [
  //       { NOM: '', COGNOM: '', DNI: '', DATA_NAIXAMENT: '', NUM_SS: '', INICI_PRACTIQUES: '', FI_PRACTIQUES: '' }
  //     ];
  //     sheetName = 'Plantilla Alumnes';
  //   } else if (type === 'practices') {
  //     templateData = [
  //       { DNI: '', January: '', February: '', March: '', April: '', May: '', June: '', July: '', August: '', September: '', October: '', November: '', December: '' }
  //     ];
  //     sheetName = 'Plantilla Practiques';
  //   }

  //   const fileUrl = `/public/docs/Plantilla Alumnes.xlsx`;

  //   const worksheet = XLSX.utils.json_to_sheet(templateData);
  //   const workbook = {
  //     Sheets: { [sheetName]: worksheet },
  //     SheetNames: [sheetName]
  //   };
  //   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   saveAs(data, `${sheetName}.xlsx`);
  // };


//   const downloadTemplate = (type) => {
//   let templateData = [];
//   let sheetName = '';

//   if (type === 'students') {
//     // Descargar directamente desde el servidor
//     const fileUrl = `/docs/Plantilla_Alumnes.xlsx`; // URL limpia sin espacios

//     // Crear un enlace para descargar
//     const link = document.createElement('a');
//     link.href = fileUrl;
//     link.setAttribute('download', 'Plantilla_Alumnes.xlsx');
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   } else if (type === 'practices') {
//     // Generar la plantilla de prácticas dinámicamente
//     templateData = [
//       // { DNI: '', January: '', February: '', March: '', April: '', May: '', June: '', July: '', August: '', September: '', October: '', November: '', December: '' }
//       { "DNI NIE PASS": '', COGNOMS: '', NOM: '', GENER: '', FEBRER: '', MARÇ: '', ABRIL: '', MAIG: '', JUNY: '', JULIOL: '', AGOST: '', SETEMBRE: '', OCTUBRE: '', NOVEMBRE: '', DESEMBRE: ''}
//     ];
//     sheetName = 'Plantilla Practiques';

//     const worksheet = XLSX.utils.json_to_sheet(templateData);
//     const workbook = {
//       Sheets: { [sheetName]: worksheet },
//       SheetNames: [sheetName]
//     };

//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
//     saveAs(data, `${sheetName}.xlsx`);
//   }
// };

  const downloadTemplate = (type) => {
    let fileUrl = '';
    let fileName = '';

    if (type === 'students') {
      // Descargar directamente la plantilla de estudiantes desde el servidor
      fileUrl = `/docs/Plantilla_Alumnes.xlsx`; // Ruta en el servidor
      fileName = 'Plantilla_Alumnes.xlsx';
    } else if (type === 'practices') {
      // Descargar directamente la plantilla de prácticas desde el servidor
      fileUrl = `/docs/Plantilla_Practiques.xlsx`; // Asegúrate de tener este archivo en tu servidor
      fileName = 'Plantilla_Practiques.xlsx';
    }

    // Crear un enlace para descargar
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  return (
    <div className="grid grid-cols-12 pr-2 text-center bg-white border-t-2 border-indigo-800 print:hidden">
      {user.role_id === 2 && (
      <div className="flex items-center justify-start col-span-4">
        <button
          onClick={exportToExcel}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
          style={{ width: '85px', marginRight: '5px', height: '85px' }}
        >
          <img
            src={logoExcel}
            alt="icon"
          />
        </button>
        <button
          onClick={exportToText}
          disabled={rowsEmpty}
          hidden={rowsEmpty}
          className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
          style={{ width: '85px', marginRight: '5px', height: '85px' }}
        >
          <span
              title="EXPORTAR TXT PER A3"
              className="w-full flex flex-row items-center"
            >
            <img
              src={logoTxt}
              alt="icon"
              style={{ width: '70%' }} 
            />
          </span>
        </button>
      </div>
      )}
      {user.role_id === 2 && (
       <div className="flex items-center justify-end col-span-8 py-5">
        <select
          value={selectedCodiCentre}
          onChange={(e) => setSelectedCodiCentre(e.target.value)}
          className="font-bold rounded-lg text-black bg-white px-4 py-3 cursor-pointer"
        >
          <option value="" disabled>Seleccionar Codi Centre</option>
          {Object.keys(schoolData).map(codi => (
            <option key={codi} value={codi}>
              {codi} - {schoolData[codi]} 
            </option>
          ))}
        </select>
      </div>
      
      )}
      {user.role_id === 1 && (
      <div className="flex items-center justify-start col-span-6">
        <div className="contents">
          <button
            className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
            style={{ width: '85px', marginRight: '-60px', height: '85px', zIndex: 2 }}
          >
            <img
              src={logoExcelRound}
              alt="icon"
              style={{ width: '65px', height: '65px' }} // Ajusta el tamaño según sea necesario
              onClick={() => downloadTemplate('students')}
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-[#4DA462] px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-800 hover:shadow-lg hover:scale-105"
            onClick={() => downloadTemplate('students')}
            style={{ paddingLeft: '45px' }} // Ajusta el espacio a la izquierda para acomodar la imagen
          >
            Descarregar Plantilla Alumnes
          </label>
        </div>
        <button
            className="ml-3 font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
            style={{ width: '85px', marginRight: '-60px', height: '85px', zIndex: 2 }}
          >
            <img
              src={logoExcelRound}
              alt="icon"
              style={{ width: '65px', height: '65px' }}
              onClick={() => downloadTemplate('practices')} // Ajusta el tamaño según sea necesario
            />
          </button>
          <label
            className="font-bold rounded-lg text-white bg-[#4DA462] px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-800 hover:shadow-lg hover:scale-105"
            onClick={() => downloadTemplate('practices')}
            style={{ paddingLeft: '45px' }} // Ajusta el espacio a la izquierda para acomodar la imagen
          >
            Descarregar Plantilla Pràctiques
          </label>
      </div>
      )}
      {user.role_id === 1 && (
        <div className="flex items-center justify-end col-span-6 py-5">
          <label className="ml-4 font-bold rounded-lg text-white bg-indigo-800 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-600 hover:shadow-lg hover:scale-105">
            Importar Alumnes
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileUpload}
              className="hidden"
            />
          </label>
          <label className="ml-4 font-bold rounded-lg text-white bg-indigo-800 px-4 py-3 cursor-pointer transition duration-300 ease-in-out transform hover:bg-indigo-600 hover:shadow-lg hover:scale-105">
            Importar Pràctiques
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileUploadPracts}
              className="hidden"
            />
          </label>
        </div>
      )}
      {pageOptions.length > 1 && (
        <div className="col-span-4 place-self-center">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="font-bold text-white bg-green-700 rounded-full disabled:opacity-0"
            style={{ width: '24px', marginRight: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ transform: 'rotate(180deg)', margin: 'auto' }}
            />
          </button>
          <span>
            Pàgina <strong>{(pageIndex + 1).toLocaleString()}</strong> de{' '}
            <strong>{pageOptions.length.toLocaleString()} {' ('}
              {datos.length.toLocaleString()} registres{')'}
            </strong>
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="font-bold text-white bg-green-700 rounded-full disabled:opacity-0"
            style={{ width: '24px', marginLeft: '7px', height: '24px' }}
          >
            <img
              src={flecha}
              width="15"
              height="15"
              className="p-px"
              style={{ margin: 'auto' }}
            />
          </button>
        </div>
      )}
      {pageOptions.length > 1 && (
        <select
          className="self-center float-right col-span-4 p-1 font-semibold border-2 border-green-700 rounded outline-none bg-green-50 justify-self-end"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[25, 75, 150, 250, 500].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} registres
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

StudentsTableBar.propTypes = {
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  datos: PropTypes.array,
  onSuccess: PropTypes.func.isRequired, // Ensure this is here
  onSuccessPracts: PropTypes.func.isRequired, // Ensure this is here

};
